import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{class:_vm.gs.isMobile() ? '' : 'dxa_modal'},[_c(VCardTitle,{staticClass:"mb-6"},[_c('h4',{staticClass:"dxa_modal_title h4"},[_vm._v(_vm._s(_vm.$t("add_csv")))])]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.checkForm()}}},[_c(VFileInput,{attrs:{"prefix":"","label":"CSV","accept":"csv/*","rules":[_vm.required]},model:{value:(_vm.csvFile),callback:function ($$v) {_vm.csvFile=$$v},expression:"csvFile"}}),_c(VCardActions,{staticClass:"pl-0 dxa_modal_actions"},[_c(VBtn,{staticClass:"dxa_modal_btnSuccess",attrs:{"color":"primary","min-width":"100","loading":_vm.loading,"type":"submit"}},[_vm._v(_vm._s(_vm.$t("save")))]),_c(VBtn,{staticClass:"dxa_modal_btnError",attrs:{"color":"red","min-width":"100"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1),(_vm.alertErrorUnkown)?_c(VAlert,{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.$t("an_error_occurred"))+" ")]):_vm._e(),(_vm.alertError)?_c('AlertErrorComponentDict',{attrs:{"errorData":_vm.errorMessages}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }