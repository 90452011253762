<template>
  <div>
    <v-form ref="form" @submit.stop.prevent="checkForm()">
      <div>
        <p class="filled-input-label">{{ $t("bylaws_input_label") }}</p>
        <v-list-item
          @click="removeDocument('bylaws')"
          v-if="vehicleDocuments.bylaws"
        >
          <template>
            <v-icon class="icon-file-close" aria-label="Close">
              mdi-close
            </v-icon>
          </template>

          <v-list-item-title>{{ $t("bylaws") }}</v-list-item-title>
        </v-list-item>
        <v-file-input
          v-else
          v-model="vehicleDocuments.bylaws"
          truncate-length="15"
          accept="pdf/*"
          label="File input"
        ></v-file-input>
      </div>
      <div>
        <p class="filled-input-label">
          {{ $t("shareholders_agreement_input_label") }}
        </p>
        <v-list-item
          @click="removeDocument('shareholderAgreement')"
          v-if="vehicleDocuments.shareholderAgreement"
        >
          <template>
            <v-icon class="icon-file-close" aria-label="Close">
              mdi-close
            </v-icon>
          </template>

          <v-list-item-title>{{
            $t("shareholders_agreement")
          }}</v-list-item-title>
        </v-list-item>
        <v-file-input
          v-else
          v-model="vehicleDocuments.shareholderAgreement"
          truncate-length="15"
          accept="pdf/*"
          label="File input"
        ></v-file-input>
      </div>
      <v-card-actions class="pl-0 dxa_modal_actions">
        <v-btn
          data-test="Vehicle:AddVehicle:BtnSaveVehicle"
          class="dxa_modal_btnSuccess"
          color="primary"
          min-width="100"
          :loading="loading"
          type="submit"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          class="dxa_modal_btnError"
          color="secondary"
          min-width="100"
          @click="goBack"
        >
          {{ $t("go_back") }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "AddVehicleDocuments",
  data() {
    return {
      vehicleDocuments: {
        bylaws: null,
        shareholderAgreement: null,
      },
      bylawsString64: null,
      shareholderAgreementString64: null,
      edit: false,
      loading: false,
      isSubmit: false,
    };
  },

  props: {
    VehicleInfo: Object,
    VehicleRequestSuccess: Boolean,
    VehicleRequestLoading: Boolean,
  },
  watch: {
    VehicleRequestSuccess() {
      this.loading = this.VehicleRequestLoading;

      if (this.VehicleRequestSuccess && this.isSubmit) {
        this.isSubmit = false;
      }
    },
    VehicleRequestLoading() {
      if (this.isSubmit) {
        this.loading = this.VehicleRequestLoading;
      }
    },
  },
  created() {
    const { bylaws, shareholderAgreement } = this.VehicleInfo;

    // confirmar se o retorno do back vai ser esse mesmo, para fazer a edição
    if (bylaws) {
      this.vehicleDocuments.bylaws = bylaws;
    }

    if (shareholderAgreement) {
      this.vehicleDocuments.shareholderAgreement = shareholderAgreement;
    }

    if (bylaws || shareholderAgreement) {
      this.edit = true;
    } else {
      this.edit = false;
    }
  },
  methods: {
    removeDocument(document) {
      delete this.vehicleDocuments[document];
      this.$emit("removeVehicleDocuments", document);
      this.$forceUpdate();
    },
    goBack() {
      this.$router.push({ path: "/vehicle" });
    },
    checkForm: async function () {
      let result = this.$refs.form.validate();
      if (result) {
        this.isSubmit = true;
        let payload = {
          ...this.VehicleInfo,
        };

        payload.bylaws = this.vehicleDocuments.bylaws;
        payload.shareholderAgreement =
          this.vehicleDocuments.shareholderAgreement;

        // quando tiver edit fazer o if para levar a prop de true / false
        const submitProps = {
          isOtherComponent: true,
          edit: this.edit,
          payload,
        };

        this.$emit("addVehicleDocuments", submitProps);
      }
    },
  },
};
</script>

<style scoped>
.filled-input-label {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #535353;
}

.icon-file-close {
  margin-right: 20px;
}
</style>
