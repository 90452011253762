<template>
  <div>
    <v-dialog max-width="564" v-model="visible">
      <div
        class="dialog-publish-round-pipeline-error-bg"
        data-test="Vehicle:ErrorHandler"
      >
        <div class="dialog-publish-round-pipeline-error-content">
          <font-awesome-icon
            icon="fa-solid fa-circle-xmark"
            class="dialog-publish-round-pipeline-alert-icon-error"
          />
          <h1 class="dialog-publish-round-pipeline-error-content-title">
            {{ $t("error") }}
          </h1>

          <ul
            class="dialog-publish-round-pipeline-error-content-text dialog-publish-round-pipeline-error-content-list"
          >
            <ul
              class="error-category-container"
              v-for="(errorList, key) in errorData"
              :key="key"
            >
              <span class="error-category">{{ $t(key) }}</span>
              <li v-for="(error, z) in errorList" :key="z">
                {{ $t(error) }}
              </li>
            </ul>
          </ul>
          <div class="dialog-publish-round-pipeline-error-button">
            <NewDxaButton
              @btnFunction="$emit('close')"
              :outline="true"
              :title="$t('close')"
            />
          </div>
        </div>
        <font-awesome-icon
          icon="fa-solid fa-xmark"
          class="dialog-publish-round-pipeline-error-icon-close-dialog"
          @click="$emit('close')"
          data-test="ErrorHandler:Close"
        />
      </div>
    </v-dialog>
  </div>
</template>
<script>
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
export default {
  name: "ErrorHandler",
  props: {
    errorData: Object,
    visible: Boolean,
  },
  data() {
    return {
      errorKeys: null,
    };
  },
  components: {
    NewDxaButton,
  },
  mounted() {
    const keys = Object.keys(this.errorData);
    this.errorKeys = keys;
    this.$forceUpdate();
  },
};
</script>

<style scoped>
.dialog-container {
  background-color: var(--white);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dialog-icon {
  width: 50px;
  height: 50px;
}

.icon-success {
  color: var(--primary);
}

.icon-error {
  color: #ff0000;
}

.dialog-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 12px;
}

.dialog-subtitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.dialog-button-container {
  width: 30%;
  margin-top: 12px;
}

/* estilos do modal de publicação de uma rodada no pipeline */
.dialog-publish-round-pipeline-bg,
.dialog-publish-round-pipeline-error-bg,
.dialog-publish-round-success-bg {
  background: var(--white);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  position: relative;
  padding: 24px;
}

.dialog-publish-round-pipeline-content,
.dialog-publish-round-pipeline-error-content,
.dialog-publish-round-success-content {
  display: grid;
  place-items: center;
  margin-top: 20px;
}

.dialog-publish-round-pipeline-content-alert-icon {
  width: 44px;
  height: 38px;
  color: #ff9601;
  margin-bottom: 12px;
}

.dialog-publish-round-pipeline-content-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 10px;
}

.dialog-publish-round-pipeline-content-text {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 28px;
}

.dialog-publish-round-pipeline-content-buttons {
  display: flex;
  align-items: center;
  gap: 24px;
}

.dialog-publish-round-pipeline-content-button {
  min-width: 192px;
}

.dialog-publish-round-pipeline-icon-close-dialog,
.dialog-publish-round-pipeline-error-icon-close-dialog,
.dialog-publish-round-success-icon-close {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: #afafaf;
}

.dialog-publish-round-pipeline-error-content-title {
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 10px;
  text-align: center;
}

.dialog-publish-round-pipeline-error-content-text,
.dialog-publish-round-pipeline-error-content-tex > li {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.dialog-publish-round-pipeline-error-content-list {
  margin-bottom: 37px;
  list-style: none;
  display: grid;
  gap: 4px;
}

.dialog-publish-round-pipeline-error-content-list > li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.dialog-publish-round-pipeline-error-content-list > li::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: var(--dark);
  border-radius: 50%;
}

.dialog-publish-round-pipeline-alert-icon-error {
  color: #ff0000;
  width: 44px;
  height: 44px;
  margin-bottom: 10px;
}

.dialog-publish-round-success-content {
  margin-top: 40px;
}

.dialog-publish-round-title {
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 10px;
  text-align: center;
}

.dialog-publish-round-text {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 38px;
}

.dialog-publish-round-success-icon {
  color: var(--primary);
  width: 44px;
  height: 44px;
  margin-bottom: 10px;
}

.error-category {
  font-weight: bold;
}

.error-category-container {
  text-align: justify;
  margin-bottom: 16px;
}
</style>
