import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VForm,{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.checkForm()}}},[_c('div',[_c('p',{staticClass:"filled-input-label"},[_vm._v(_vm._s(_vm.$t("bylaws_input_label")))]),(_vm.vehicleDocuments.bylaws)?_c(VListItem,{on:{"click":function($event){return _vm.removeDocument('bylaws')}}},[[_c(VIcon,{staticClass:"icon-file-close",attrs:{"aria-label":"Close"}},[_vm._v(" mdi-close ")])],_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("bylaws")))])],2):_c(VFileInput,{attrs:{"truncate-length":"15","accept":"pdf/*","label":"File input"},model:{value:(_vm.vehicleDocuments.bylaws),callback:function ($$v) {_vm.$set(_vm.vehicleDocuments, "bylaws", $$v)},expression:"vehicleDocuments.bylaws"}})],1),_c('div',[_c('p',{staticClass:"filled-input-label"},[_vm._v(" "+_vm._s(_vm.$t("shareholders_agreement_input_label"))+" ")]),(_vm.vehicleDocuments.shareholderAgreement)?_c(VListItem,{on:{"click":function($event){return _vm.removeDocument('shareholderAgreement')}}},[[_c(VIcon,{staticClass:"icon-file-close",attrs:{"aria-label":"Close"}},[_vm._v(" mdi-close ")])],_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("shareholders_agreement")))])],2):_c(VFileInput,{attrs:{"truncate-length":"15","accept":"pdf/*","label":"File input"},model:{value:(_vm.vehicleDocuments.shareholderAgreement),callback:function ($$v) {_vm.$set(_vm.vehicleDocuments, "shareholderAgreement", $$v)},expression:"vehicleDocuments.shareholderAgreement"}})],1),_c(VCardActions,{staticClass:"pl-0 dxa_modal_actions"},[_c(VBtn,{staticClass:"dxa_modal_btnSuccess",attrs:{"data-test":"Vehicle:AddVehicle:BtnSaveVehicle","color":"primary","min-width":"100","loading":_vm.loading,"type":"submit"}},[_vm._v(_vm._s(_vm.$t("save")))]),_c(VBtn,{staticClass:"dxa_modal_btnError",attrs:{"color":"secondary","min-width":"100"},on:{"click":_vm.goBack}},[_vm._v(" "+_vm._s(_vm.$t("go_back"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }