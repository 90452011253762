<template>
  <!-- exemplo do uso desse componente em DialogAddStockFile.vue
path: src\views\pages\vehicle\stockType\components\DialogAddStockFile.vue
 -->
  <v-alert class="ma-2" v-if="errorData" type="error">
    <div>
      <div v-for="(error, i) in errorData" :key="i">
        <span>{{ `${$t("line")}: ${++error.erroLine}` }}</span>
        <ul>
          <li v-for="(erroKey, i) in error.errosAlertKeys" :key="i">
            <span class="text-base" v-if="error.erroMainKey">{{
              `${$t(erroKey)} ( ${$t(error.erroMainKey)} )`
            }}</span>
            <span class="text-base" v-else>{{ $t(erroKey) }}</span>
          </li>
        </ul>
      </div>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: "AlertErrorComponentDict",
  props: {
    errorData: Array,
  },
};
</script>

<style scoped>
.text-base {
  font-size: 14px !important;
}
</style>
