function extractIndex(input) {
  // Use a regular expression to match the number inside square brackets
  const match = input.match(/items\[(\d+)\]/);

  // If a match is found, return the captured group (the number)
  if (match && match[1]) {
    return parseInt(match[1], 10);
  } else {
    // If no match is found, return null or handle the error as needed
    return null;
  }
}

export const getErrorDataByObject = (content) => {
  // array que vamos popular com os erros + a key do erro
  // items[0].Currency: ["field_not_null_or_empty", "invalid"] => o primeiro vai no erroMainKey e o segunda no errosAlertsKeys para mostrar no alerta.
  // ex: { errosALertsKeys: ['invalid']; erroMainKey: 'Currency' }
  // errosALertsKeys => Key que vai mostrar o erro para o usuário
  // erroMainKey => Key que mostra a coluna que o usuário errou. Ex: Currency, StockTypeId... essa key pode ser null
  // resultado: Inválido (Currency) - quando existir o errosALertsKeys e erroMainKey : Inválido quando tiver o errosALertsKeys
  let erroData = [];

  Object.keys(content).forEach((key) => {
    // pegando exatamente a key do erro que o back volta
    //ex: items[0].Currency => Currency
    const str = key;
    const [, errorKey] = str.split("].");

    // populando a array para mostrar no alerta
    erroData = [
      ...erroData,
      {
        errosAlertKeys: [...content[key]],
        erroMainKey: errorKey ? errorKey : null,
        erroLine: extractIndex(key),
      },
    ];
  });

  return erroData;
};
