<template>
  <v-container v-if="allLoading">
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
  <v-container v-else id="category-table" fluid tag="section">
    <base-material-card
      icon="mdi-currency-usd"
      inline
      color="secondary"
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h5">
          <b>{{ $t("add_new_vehicle") }}</b>
        </h1></template
      >
      <v-tabs v-model="tab" grow>
        <v-tab data-test="Vehicle:AddVehicle:BtnToDataVehicle">{{
          $t("vehicle_data")
        }}</v-tab>
        <v-tab
          :disabled="!nextStep"
          v-show="vehicle.closingType === vehicleClosingOptionsEnum.CLOSING_SA"
          >{{ $t("documents_title") }}</v-tab
        >
        <v-tab
          :disabled="!nextStep"
          data-test="Vehicle:AddVehicle:BtnToStock"
          >{{ $t("stocks") }}</v-tab
        >
      </v-tabs>
      <v-card-text v-if="tab === vehicleTabEnum.vehicleData">
        <v-form ref="form" @submit.stop.prevent="checkForm()">
          <v-radio-group
            class="mt-5"
            row
            hide-details
            v-model="vehicle.vehicleNationality"
            @change="handleSelectNationality"
          >
            <v-radio
              class="mr-10"
              color="primary"
              label="Veículo Nacional"
              :value="vehicleNationalityOptions.NATIONAL"
              data-test="Vehicle:AddVehicle:Nationality:National"
            ></v-radio>
            <v-radio
              color="primary"
              label="Veículo Internacional"
              :value="vehicleNationalityOptions.INTERNATIONAL"
              data-test="Vehicle:AddVehicle:Nationality:International"
            ></v-radio>
          </v-radio-group>
          <span></span>
          <v-radio-group
            class="mt-5"
            row
            hide-details
            v-model="vehicle.typeVehicle"
          >
            <v-radio
              class="mr-10"
              color="primary"
              :label="$t('captable_type')"
              :value="typeVehicle.CAPTABLE"
              data-test="Vehicle:AddVehicle:TypeVehicle:CAPTABLE"
            ></v-radio>
            <v-radio
              color="primary"
              :label="$t('investment_fund_type')"
              :value="typeVehicle.FUND"
              data-test="Vehicle:AddVehicle:TypeVehicle:FUND"
            ></v-radio>            
          </v-radio-group>
          <div class="close-options-select-container">
            <span class="closing-options-select-label"
              >{{ $t("closing_config") }}
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <font-awesome-icon
                      icon="fa-solid fa-circle-info"
                      class="closing-type-icon-info"
                    />
                  </div>
                </template>
                <span class="closing-type-info-text">{{
                  $t("closing_type_tolltip_text")
                }}</span>
              </v-tooltip>
            </span>
            <v-select
              :items="closingOptions"
              v-model="vehicle.closingType"
              item-text="text"
              item-value="value"
              class="closing-options-select"
              outlined
              :label="$t('select_option')"
              :rules="[required]"
              data-test="Vehicle:AddVehicle:ClosingOptions"
            >
            </v-select>
          </div>
          <div class="form-add-vehicle-container">
            <span class="form-add-vehicle-title">{{
              $t("vehicle_banker_data")
            }}</span>
            <v-text-field
              data-test="Vehicle:AddVehicle:FirstName"
              v-model="vehicle.name"
              :label="$t('name')"
              :rules="[required]"
            />
            <v-text-field
              data-test="Vehicle:AddVehicle:Cnpj"
              placeholder="00.000.000/0000-00"
              v-mask="['##.###.###/####-##']"
              :rules="[(v) => (!!v && v.length === 18) || required()]"
              v-model="vehicle.cnpj"
              :label="$t('company_id')"
              v-if="!vehicleIsInternational"
            />
            <v-text-field
              data-test="Vehicle:AddVehicle:Nire"
              v-model="vehicle.nire"
              :label="$t('nire')"
              :rules="[required]"
              v-if="!vehicleIsInternational"
            />
            <div v-if="!vehicleIsInternational">
              <v-autocomplete
                class="field"
                :placeholder="$t('insert_bank_code')"
                color="primary"
                :no-data-text="$t('not_found')"
                :items="banks"
                :filter="customFilter"
                item-value="COMPE"
                v-model="bankData.bank"
                data-test="Vehicle:AddVehicle:Bank"
              >
                <template slot="selection" slot-scope="data">
                  <span>{{
                    `${data.item.COMPE} - ${data.item.LongName}`
                  }}</span>
                </template>
                <template slot="item" slot-scope="data">
                  <span>{{
                    `${data.item.COMPE} - ${data.item.LongName}`
                  }}</span>
                </template>
              </v-autocomplete>
              <v-text-field
                v-model="bankData.agency"
                data-test="Vehicle:AddVehicle:BankAgency"
                :placeholder="$t('insert_bank_agency')"
              />
              <v-text-field
                v-model="bankData.account"
                class="field"
                :placeholder="$t('insert_bank_account')"
                data-test="Vehicle:AddVehicle:BankAccount"
              />
              <v-text-field
                v-model="bankData.pix"
                class="field"
                :placeholder="$t('insert_bank_pix')"
                data-test="Vehicle:AddVehicle:BankPix"
              />
            </div>
            <div v-else>
              <v-text-field
                v-model="interBankAccounts.bank"
                class="field"
                :placeholder="$t('insert_bank_name')"
                data-test="Vehicle:AddVehicle:Bank"
              />
              <v-text-field
                v-model="interBankAccounts.account"
                class="field"
                :placeholder="$t('insert_bank_account')"
                data-test="Vehicle:AddVehicle:Account"
              />
              <v-text-field
                v-model="interBankAccounts.recipient"
                class="field"
                placeholder="Beneficiário"
                data-test="Vehicle:AddVehicle:Recipient"
              />
              <v-text-field
                v-model="interBankAccounts.address"
                class="field"
                :placeholder="$t('address')"
                data-test="Vehicle:AddVehicle:Address"
              />
              <v-text-field
                v-model="interBankAccounts.swift"
                class="field"
                :placeholder="$t('swift')"
                data-test="Vehicle:AddVehicle:Swift"
              />
              <v-text-field
                v-model="interBankAccounts.routingNumber"
                class="field"
                :placeholder="$t('aba')"
                data-test="Vehicle:AddVehicle:Aba"
              />
              <v-textarea
                v-model="interBankAccounts.otherInformation"
                class="field"
                :placeholder="$t('other_informations')"
                data-test="Vehicle:AddVehicle:OtherInfo"
              />
            </div>
            <v-card-actions class="pl-0 dxa_modal_actions">
              <v-btn
                data-test="Vehicle:AddVehicle:BtnSaveVehicle"
                class="dxa_modal_btnSuccess"
                color="primary"
                min-width="100"
                :loading="loading"
                type="submit"
                >{{ $t("save") }}</v-btn
              >
              <v-btn
                class="dxa_modal_btnError"
                color="secondary"
                min-width="100"
                @click="goBack"
              >
                {{ $t("go_back") }}
              </v-btn>
            </v-card-actions>
          </div>
        </v-form>
      </v-card-text>
      <VehicleDocuments
        v-if="tab === vehicleTabEnum.vehicleDocuments"
        :VehicleInfo="vehicle"
        :VehicleRequestSuccess="vehicleRequestSuccess"
        :VehicleRequestLoading="vehicleRequestLoading"
        @addVehicleDocuments="(e) => addVehicleDocuments(e)"
        @removeVehicleDocuments="(e) => removeVehicleDocuments(e)"
      />
      <VehicleStocks
        @submitVehicle="(e) => submit(e)"
        @reloadVehicleData="() => getVehicleInfo()"
        :VehicleIsInternational="vehicleIsInternational"
        :VehicleInfo="vehicle"
        :BankAccountsProp="bankAccountsProp"
        :VehicleRequestSuccess="vehicleRequestSuccess"
        :VehicleRequestLoading="vehicleRequestLoading"
        v-if="tab === vehicleTabEnum.vehicleStocks"
      />
    </base-material-card>
    <ErrorHandler
      @close="errorHandlerVisible = false"
      :visible="errorHandlerVisible"
      :errorData="errorData"
    />
  </v-container>
</template>

<script>
import VehicleStocks from "./steps/AddVehicleStocks.vue";
import ApiService from "@/services/ApiService";
import { formatCnpj } from "@/shared/helpers/cnpjHelper.js";
import { vehicleNationality, typeVehicle } from "@/shared/enums/VehicleEnum";
import banksData from "@/shared/data/banks.json";
import VehicleDocuments from "./steps/AddVehicleDocuments.vue";
import ErrorHandler from "@/components/dxa/ErrorHandler/ErrorHandler.vue";
import { vehicleClosingOptionsEnum } from "@/shared/enums/VehicleEnum";
import { vehicleTabEnum } from "../enum/vehicleTabEnum";

export default {
  name: "AddVehicle",
  components: { VehicleStocks, VehicleDocuments, ErrorHandler },
  data() {
    return {
      banks: banksData,
      apiService: new ApiService(),
      valid: true,
      loading: false,
      edit: false,
      error: null,
      vehicleRequestLoading: false,
      vehicleRequestSuccess: false,
      vehicle: {
        name: null,
        cnpj: null,
        nire: null,
        vehicleStockTypes: [],
        vehicleNationality: vehicleNationality.NATIONAL,
        bylaws: null,
        shareholderAgreement: null,
        closingType: null,
        typeVehicle: typeVehicle.CAPTABLE,
      },
      bankData: {},
      interBankAccounts: {},
      bankAccountsProp: [],
      vehicleDocuments: {
        bylaws: null,
        shareholderAgreement: null,
      },
      einMask: null,
      tab: 0,
      allLoading: false,
      nextStep: false,
      vehicleId: this.$route.params.vehicleId,
      vehicleNationalityOptions: vehicleNationality,
      vehicleIsInternational: false,
      vehicleNationality: vehicleNationality,
      errorData: null,
      errorHandlerVisible: false,
      vehicleClosingOptionsEnum,
      closingOptions: [],
      vehicleTabEnum,
      typeVehicle: typeVehicle,
    };
  },
  async created() {
    const tabRoute = this.$route.query.tab;
    // só chama esse method quando estiver na criação do veículo
    if (this.vehicle.vehicleNationality && !this.vehicleId) {
      this.handleSelectNationality(this.vehicle.vehicleNationality);
    }

    if (this.vehicleId) {
      await this.getVehicleInfo();
      this.edit = true;
    }

    if (tabRoute && tabRoute === String(this.vehicleTabEnum.vehicleStocks)) {
      this.tab = this.vehicleTabEnum.vehicleStocks;
      this.$route.params.tab = null;
    }
  },
  beforeMount() {
    if (this.$route.query.tab) {
      // Remove o parâmetro 'tab' da URL
      const { tab, ...queryWithoutTab } = this.$route.query;
      this.$router.replace({
        path: this.$route.path,
        query: queryWithoutTab,
      });
    }
  },
  computed: {
    required(value) {
      return (value) => !!value || this.$t("required");
    },
  },
  watch: {
    "vehicle.name"() {
      this.preventNextStep();
    },
    "vehicle.nire"() {
      this.preventNextStep();
    },
    "vehicle.cnpj"() {
      this.preventNextStep();
    },
    "vehicle.closingType"() {
      this.preventNextStep();
    },
    "vehicle.vehicleNationality"() {
      // controlando o estado se o veículo é internacional ou não como o nextStep depende dessa prop
      // para fazer o check do next step é importante que ocorra a verificação primeiro antes de chamar o method
      if (
        this.vehicle.vehicleNationality ===
        this.vehicleNationalityOptions.INTERNATIONAL
      ) {
        this.vehicleIsInternational = true;
        this.vehicle.cnpj = null;
        this.vehicle.nire = null;
      } else {
        this.vehicleIsInternational = false;
      }
      this.preventNextStep();
    },
  },
  methods: {
    handleSelectNationality(nationality) {
      this.vehicle.closingType = null;
      const noClosingOption = {
        text: this.$t("no_closing"),
        value: this.vehicleClosingOptionsEnum.NO_CLOSING,
      };

      if (nationality === vehicleNationality.NATIONAL) {
        this.closingOptions = [
          noClosingOption,
          {
            text: this.$t("sa_closing"),
            value: this.vehicleClosingOptionsEnum.CLOSING_SA,
          },
        ];
        this.$forceUpdate();
      } else if (nationality === vehicleNationality.INTERNATIONAL) {
        this.closingOptions = [
          noClosingOption,
          {
            text: this.$t("llc_closing"),
            value: this.vehicleClosingOptionsEnum.CLOSING_LLC,
          },
        ];
        this.$forceUpdate();
      }
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.COMPE.toLowerCase();
      const textTwo = item.LongName.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    openStockDialog() {
      this.stock_dialog = true;
    },
    closeStockModal() {
      this.stock_dialog = false;
    },
    async getVehicleInfo() {
      this.allLoading = true;
      await this.apiService
        .getRequest(`investmentvehicle/vehiclestockvalue/${this.vehicleId}`)
        .then(({ content }) => {
          // precisa popular a prop de nacionalidade primeiro, para chamar as opções do campo de closing de acordo com a nacionalidade.
          this.vehicle.vehicleNationality = content.vehicleNationality;
          this.handleSelectNationality(content.vehicleNationality);

          this.vehicle = {
            id: content.id,
            name: content.name,
            nire: content.nire,
            cnpj: formatCnpj(content.cnpj),
            vehicleStockTypes: content.vehicleStockTypes,
            vehicleNationality: content.vehicleNationality,
            bylaws: content.bylaws,
            shareholderAgreement: content.shareholderAgreement,
            closingType: content.closingType,
            typeVehicle: content.typeVehicle,
          };
          if (content.bankAccounts.length) {
            this.bankData = content.bankAccounts[0];
            this.bankAccountsProp = content.bankAccounts;
          }
          if (content.interBankAccounts.length) {
            this.interBankAccounts = content.interBankAccounts[0];
          }
          this.allLoading = false;
          this.preventNextStep();
        })
        .catch((err) => {
          this.allLoading = false;
          return err;
        });
    },
    checkForm: async function () {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let result = this.$refs.form.validate();
      if (result) {
        await this.submit();
      } else {
        this.loading = false;
      }
    },
    addVehicleDocuments(e) {
      this.vehicleDocuments.bylaws = e.payload.bylaws;
      this.vehicleDocuments.shareholderAgreement =
        e.payload.shareholderAgreement;

      this.submit(e);
    },
    removeVehicleDocuments(e) {
      delete this.vehicle[e];
    },
    submit: async function (submitProps) {
      this.vehicleRequestLoading = true;
      // por default submitProps vai ser esse objeto
      if (!submitProps) {
        submitProps = {
          isOtherComponent: false,
          edit: false,
          payload: null,
        };
      }

      let payload;

      if (submitProps.isOtherComponent) {
        payload = {
          ...submitProps.payload,
          interBankAccounts:
            this.interBankAccounts.bank || this.interBankAccounts.account
              ? [this.interBankAccounts]
              : null,
          bankAccounts:
            this.bankData.bank || this.bankData.agency || this.bankData.account
              ? [this.bankData]
              : null,
          ...this.vehicleDocuments,
        };
      } else {
        payload = {
          ...this.vehicle,
          bankAccounts:
            this.bankData.bank || this.bankData.agency || this.bankData.account
              ? [this.bankData]
              : null,
          ...this.vehicleDocuments,
        };
      }

      if (payload.cnpj) {
        payload.cnpj = payload.cnpj
          .replace(/\./g, "")
          .replace(/-/g, "")
          .replace("/", "");
      }

      // quando essas keys forem null vai ser preciso remover do payload para o formData não enviar como string e quebrar a tabela
      if (!payload.cnpj) {
        delete payload.cnpj;
      }

      if (!payload.nire) {
        delete payload.nire;
      }

      let formData = new FormData();

      // populando  o formData com o payload
      //  só passa pelo loop para popular o formData a key que não for uma array
      for (var key in payload) {
        if (
          !(key === "bankAccounts") &&
          !(key === "vehicleStockTypes") &&
          !(key === "interBankAccounts")
        ) {
          formData.append(key, payload[key]);
        }
      }

      if (!this.vehicleIsInternational) {
        formData.append("interBankAccounts", JSON.stringify([]));
        formData.append(
          "bankAccounts",
          payload.bankAccounts && payload.bankAccounts.length
            ? JSON.stringify(payload.bankAccounts)
            : null
        );
      } else {
        formData.append("bankAccounts", JSON.stringify([]));
        formData.append(
          "interBankAccounts",
          this.interBankAccounts && this.interBankAccounts.bank
            ? JSON.stringify([this.interBankAccounts])
            : null
        );
      }

      formData.append(
        "vehicleStockTypes",
        payload.vehicleStockTypes && payload.vehicleStockTypes.length
          ? JSON.stringify(payload.vehicleStockTypes)
          : null
      );

      if (payload.id) {
        await this.apiService
          .putRequest("investmentvehicle/update", formData)
          .then((result) => {
            this.$toast.success(this.$t("saved"));
            this.vehicleRequestSuccess = true;
          })
          .catch(async (err) => {
            const { content } = err.body;
            this.errorData = err.body.content;
            this.errorHandlerVisible = true;
            // reload vehicle data
            await this.getVehicleInfo();
          });
        this.loading = false;
        this.vehicleRequestLoading = false;
        this.vehicleRequestSuccess = false;
      } else {
        await this.apiService
          .postRequest("investmentvehicle/create", formData)
          .then((result) => {
            const { id } = result.content;

            this.$router.push({ path: `/vehicle/edit/${id}` });
            this.$toast.success(this.$t("saved"));
            this.vehicleRequestSuccess = true;
          })
          .catch((err) => {
            this.errorData = err.body.content;
            this.errorHandlerVisible = true;
          });
        this.loading = false;
        this.vehicleRequestLoading = false;
        this.vehicleRequestSuccess = false;
      }
    },
    preventNextStep() {
      let { name, cnpj, nire, closingType } = this.vehicle;

      // quando o veículo for nacional a validação continua a mesma
      if (!this.vehicleIsInternational) {
        if (name && cnpj && cnpj.length === 18 && nire && closingType) {
          this.nextStep = true;
        } else {
          this.nextStep = false;
        }
      }

      // quando o veículo for internacional a validação vai mudar, porque só é preciso preencher o nome do veículo e closingType
      if (this.vehicleIsInternational) {
        if (name && closingType) {
          this.nextStep = true;
        } else {
          this.nextStep = false;
        }
      }
    },
    goBack() {
      this.$router.push({ path: "/vehicle" });
    },
  },
};
</script>

<style scoped>
.form-add-vehicle-container {
  margin-top: 32px;
  border: 1px solid var(--dark);
  padding: 20px;
}

.form-add-vehicle-title {
  font-size: 20px;
  font-family: Source Sans Pro;
  color: var(--dark);
  font-weight: normal;
  display: block;
  margin-bottom: 34px;
}

.closing-options-select {
  width: 100%;
  max-width: 580px;
  background: #fff;
  border-radius: 4px;
  height: 56px;
}

.closing-options-select-label {
  font-size: 10.5px;
  font-family: Source Sans Pro;
  color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;
}

.close-options-select-container {
  margin-top: 42px;
}

.closing-type-icon-info {
  color: #000;
  font-size: 12px;
}
</style>
