<template>
  <v-container v-if="allLoading">
    {{ vehicleStocks }}

    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
  <v-card-text v-else>
    <v-simple-table class="mt-14 mb-6 table-stocks">
      <v-btn
        data-test="Vehicle:AddVehicleStocks:BtnAddStock"
        color="primary"
        :disabled="!vehicleId"
        rounded
        @click="openStockDialog(null)"
        fab
        class="button-add-stock"
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        :disabled="!vehicleId"
        color="secondary"
        rounded
        @click="openDialogFile()"
        fab
        class="button-file-stock"
      >
        <v-icon large>mdi-file-upload</v-icon>
      </v-btn>
      <thead>
        <tr>
          <th class="text-center">Id</th>
          <th class="text-center">{{ $t("name") }}</th>
          <th class="text-center">{{ $t("description") }}</th>
          <th class="text-center">{{ $tc("stock", 1) }}</th>
          <th class="text-center"></th>
          <th class="text-center"></th>
        </tr>
      </thead>
      <tbody v-if="vehicleStocks.length > 0">
        <tr v-for="(stock, i) in vehicleStocks" :key="i">
          <td class="text-center">{{ stock.stockTypeId }}</td>
          <td class="text-center">{{ stock.stockTypeName }}</td>
          <td class="text-center">{{ stock.stockTypeDescription }}</td>
          <td class="text-center">
            {{ formatCurrency(stock.currency, stock.value, 2, 8) }}
          </td>
          <td class="text-center">
            <v-btn
              @click="handleAddNewValueStock(stock)"
              class="px-2 ml-1 secondary"
              min-width="0"
              small
            >
              <v-icon small>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              @click="openStockDialog(stock)"
              data-test="Vehicle:AddVehicleStocks:BtnEditStock"
              class="px-2 ml-1 secondary"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              @click="
                $router.push(
                  `/vehicle/${$route.params.vehicleId}/stockType/${stock.stockTypeId}/history`
                )
              "
              class="px-2 ml-1 secondary"
              min-width="0"
              small
            >
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              @click="openDeleteDialog(stock)"
              class="px-2 ml-1"
              color="red"
              min-width="0"
              small
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-alert type="info" v-if="!vehicleId">
      {{ $t("alert_required_vehicle_to_stock") }}
    </v-alert>
    <v-dialog v-if="stockDialog" v-model="stockDialog" scrollable>
      <AddStockModal
        :addNewValueStock="addNewValueStock"
        :stockEdit="stockEdit"
        :vehicleInfo="vehicleInfo"
        :vehicleIsInternational="VehicleIsInternational"
        :vehicleRequestLoading="VehicleRequestLoading"
        :vehicleRequestSuccess="VehicleRequestSuccess"
        @close="closeStockModal"
        @reloadStocks="reloadStocks"
        @reloadVehicleData="() => emitReloadVehicleData()"
      />
    </v-dialog>
    <v-dialog v-if="deleteDialog" v-model="deleteDialog">
      <DeleteConfirmationModal
        :obj="deleteObj"
        :name="deleteObj.stockTypeName"
        @close="deleteDialog = false"
        @delete="deleteStock"
        :alert="deleteDialogAlert"
      />
    </v-dialog>
    <v-dialog v-model="openDialogStockFile" v-if="openDialogStockFile">
      <DialogAddStockFile
        @success="handleSuccessStockFile"
        :vehicleId="vehicleId"
        @close="openDialogStockFile = false"
      />
    </v-dialog>
  </v-card-text>
</template>

<script>
import AddStockModal from "./AddStockToVehicleModal.vue";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal.vue";
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices/";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import DialogAddStockFile from "../../stockType/components/DialogAddStockFile";

export default {
  name: "AddVehicleStocks",
  components: { AddStockModal, DeleteConfirmationModal, DialogAddStockFile },
  props: {
    VehicleInfo: Object,
    VehicleIsInternational: Boolean,
    BankAccountsProp: Array,
    VehicleRequestSuccess: Boolean,
    VehicleRequestLoading: Boolean,
  },
  data() {
    return {
      apiService: new ApiService(),
      gs: new GeneralServices(),
      stockDialog: false,
      vehicleInfo: null,
      vehicleStocks: [],
      stockEdit: null,
      deleteDialog: false,
      deleteObj: null,
      loading: false,
      allLoading: false,
      vehicleId: this.$route.params.vehicleId,
      vehicleIsInternational: null,
      formatCurrency: formatCurrency,
      isDelete: false,
      openDialogStockFile: false,
      addNewValueStock: false,
      deleteDialogAlert: {
        show: true,
        message: "stock_type_delete_alert",
        type: "info",
      },
    };
  },
  async created() {
    this.vehicleInfo = this.VehicleInfo;
    this.vehicleIsInternational = this.VehicleIsInternational;

    if (this.vehicleId) {
      await this.getVehicleStocks();
    }
  },
  watch: {
    // watch para fazer o controle de loading e ações ao sucesso da request que é feita no componente pai
    VehicleRequestSuccess() {
      if (this.VehicleRequestSuccess && this.isDelete) {
        this.deleteDialog = false;
        this.reloadStocks();
        this.isDelete = false;
      }
    },
    VehicleRequestLoading() {
      if (this.VehicleRequestSuccess && this.isDelete) {
        this.loading = this.vehicleRequestLoading;
      }
    },
  },
  methods: {
    // method que emite a função para o componente pai chamar a request e adicionar as ações
    emitSubmitVehicle(e) {
      this.$emit("submitVehicle", e);
    },
    emitReloadVehicleData() {
      this.$emit("reloadVehicleData");
    },
    openStockDialog(edit) {
      this.addNewValueStock = false;
      this.stockEdit = edit;
      this.stockDialog = true;
    },
    handleAddNewValueStock(edit) {
      this.stockEdit = edit;
      this.addNewValueStock = true;
      this.stockDialog = true;
    },
    closeStockModal() {
      this.stockDialog = false;
    },
    openDeleteDialog(stock) {
      this.deleteDialog = true;
      this.deleteObj = stock;
    },
    openDialogFile() {
      this.openDialogStockFile = true;
    },
    async getVehicleStocks() {
      this.allLoading = true;
      await this.apiService
        .getRequest(`investmentvehicle/vehiclestockvalue/${this.vehicleId}`)
        .then((result) => {
          this.vehicleStocks = result.content.vehicleStockTypes;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_occoured"));
        });
      this.allLoading = false;
    },
    async handleSuccessStockFile() {
      await this.getVehicleStocks();
      this.openDialogStockFile = false;
    },
    async deleteStock(stockToDelete) {
      let payload = this.vehicleInfo;
      this.isDelete = true;

      const deleteStock = payload.vehicleStockTypes.filter((stock) => {
        return stock.stockTypeId !== stockToDelete.stockTypeId;
      });

      const cleanDeleteStock = deleteStock.map(
        ({ stockTypeId, value, currency }) => {
          return {
            stockTypeId,
            value,
            currency,
          };
        }
      );

      payload.vehicleStockTypes = cleanDeleteStock;

      if (payload.cnpj === "-") {
        payload.cnpj = null;
      }

      if (payload.cnpj) {
        payload.cnpj = payload.cnpj
          .replace(/\./g, "")
          .replace(/-/g, "")
          .replace("/", "");
      }

      const submitProps = {
        isOtherComponent: true,
        edit: true,
        payload,
      };

      this.$emit("submitVehicle", submitProps);
      this.deleteDialog = false;
    },
    async reloadStocks() {
      await this.getVehicleStocks();
    },
  },
};
</script>

<style scoped>
.table-stocks {
  position: relative;
}

.button-add-stock {
  position: absolute;
  right: 70px;
  top: -40px;
  margin: 0 !important;
}

.button-file-stock {
  position: absolute;
  right: 0;
  top: -40px;
  margin: 0 !important;
}
</style>
