import { render, staticRenderFns } from "./AddVehicleStocks.vue?vue&type=template&id=27466a0f&scoped=true&"
import script from "./AddVehicleStocks.vue?vue&type=script&lang=js&"
export * from "./AddVehicleStocks.vue?vue&type=script&lang=js&"
import style0 from "./AddVehicleStocks.vue?vue&type=style&index=0&id=27466a0f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27466a0f",
  null
  
)

export default component.exports