<template>
  <v-card :class="gs.isMobile() ? '' : 'dxa_modal'">
    <v-card-title class="mb-6">
      <h4 class="dxa_modal_title h4">{{ $t("add_csv") }}</h4>
    </v-card-title>
    <v-card-text>
      <v-form @submit.stop.prevent="checkForm()" ref="form">
        <v-file-input
          prefix
          label="CSV"
          accept="csv/*"
          :rules="[required]"
          v-model="csvFile"
        />

        <v-card-actions class="pl-0 dxa_modal_actions">
          <v-btn
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            :loading="loading"
            type="submit"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            @click="$emit('close')"
            class="dxa_modal_btnError"
            color="red"
            min-width="100"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
        <v-alert v-if="alertErrorUnkown" type="error">
          {{ $t("an_error_occurred") }}
        </v-alert>
        <AlertErrorComponentDict :errorData="errorMessages" v-if="alertError" />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import GeneralServices from "@/services/GeneralServices";
import ApiService from "@/services/ApiService";
import AlertErrorComponentDict from "@/components/dxa/AlertError/AlertErrorComponentDict.vue";
import { getErrorDataByObject } from "@/shared/helpers/getErrorDataByObject";

export default {
  name: "DialogAddStockFile",
  components: { AlertErrorComponentDict },
  data() {
    return {
      gs: new GeneralServices(),
      apiService: new ApiService(),
      csvFile: null,
      loading: false,
      showAlert: false,
      alertError: false,
      alertErrorUnkown: false,
      errorMessages: [],
      getErrorDataByObject,
    };
  },
  props: {
    vehicleId: Number,
  },
  methods: {
    async checkForm() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.alertError = false;
      this.alertErrorUnkown = false;
      let result = this.$refs.form.validate();

      if (result) {
        await this.submit();
      }

      if (!result) {
        this.loading = false;
      }
    },
    async submit() {
      this.alertError = false;
      this.alertErrorUnkown = false;
      this.loading = true;

      const formData = new FormData();
      formData.append("file", this.csvFile);

      await this.apiService
        .postRequest(`investmentvehicle/${this.vehicleId}/stockvalue`, formData)
        .then((resp) => {
          this.$emit("success");
          this.$toast.success(this.$t("saved"));
        })
        .catch(async (err) => {
          if (err.body.statusCode === 400) {
            const { content } = err.body;
            this.csvFile = null;
            this.errorMessages = this.getErrorDataByObject(content);
            this.alertError = true;
          } else {
            this.alertErrorUnkown = true;
          }
        });

      this.loading = false;
    },
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
};
</script>

<style scoped></style>
