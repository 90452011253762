<template>
  <v-card :class="gs.isMobile() ? '' : 'dxa_modal'">
    <v-card-title class="mb-6">
      <h4 class="dxa_modal_title h4">
        {{ getModalTitle() }}
      </h4>
    </v-card-title>
    <v-card-text>
      <v-form @submit.stop.prevent="checkForm()" ref="form">
        <v-select
          data-test="Vehicle:AddStockToVehicleModal:SelectStock"
          :disabled="edit || addNewValueStock"
          v-model="stockSelected"
          :items="allStocks"
          item-value="Id"
          item-text="Name"
          :label="vehicleId && stockEdit ? stockName : $t('stocks')"
          :rules="[required]"
        ></v-select>
        <v-select
          data-test="Vehicle:AddStockToVehicleModal:SelectCurrency"
          :rules="[required]"
          v-model="stockCurrency"
          :items="currencyOptions"
          item-text="text"
          item-value="value"
          :label="$t('currency')"
          dense
        ></v-select>
        <v-text-field
          data-test="Vehicle:AddStockToVehicleModal:StockValue"
          v-model="stockValue"
          :label="$tc('stock', 1)"
          type="number"
          class="pt-0"
          :rules="[required]"
        />
        <div class="round-date-container">
          <v-menu
            ref="endMenuDateRef"
            :close-on-content-click="false"
            v-model="stockTypeCaptureEndMenuDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                @click="() => closeAlert()"
                dense
                class="rounds-filters-date"
                style="width: 100%"
                v-model="stockTypeDate"
                v-mask="['##/##/####']"
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                label="Data"
                height="34"
              ></v-text-field>
            </template>
            <v-date-picker
              :max="maxDatePicker"
              locale="pt-BR"
              color="primary"
              v-model="stockTypeCaptureDate"
              min="1850-01-01"
              @change="saveStockTypeDate"
            ></v-date-picker>
          </v-menu>
        </div>

        <div>
          <v-checkbox v-model="useValue">
            <template v-slot:label>
              <span style="color: var(--dark)">{{ $t("use_stock") }}</span>
            </template>
          </v-checkbox>
        </div>

        <v-card-actions class="pl-0 dxa_modal_actions">
          <v-btn
            data-test="Vehicle:AddStockToVehicleModal:BtnSaveStock"
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            :loading="loading"
            type="submit"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            class="dxa_modal_btnError"
            color="red"
            min-width="100"
            @click="$emit('close', null)"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-form>

      <v-alert v-if="alert.show" type="error">
        {{ alert.message }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
import GeneralServices from "@/services/GeneralServices";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { parseStringDate } from "@/shared/helpers/dateHelper.js";

export default {
  name: "AddStockToVehicleModal",
  props: {
    addNewValueStock: Boolean,
    vehicleInfo: Object,
    stockEdit: Object,
    editByHistoryTable: Boolean,
    vehicleIsInternational: Boolean,
    vehicleRequestSuccess: Boolean,
    vehicleRequestLoading: Boolean,
  },
  data() {
    return {
      apiService: new ApiService(),
      gs: new GeneralServices(),

      edit: false,
      allStocks: [],
      stockSelected: null,
      stockValue: null,
      stockName: null,
      update: false,
      stockTypeCaptureEndMenuDate: false,
      stockTypeCaptureDate: null,
      stockTypeDate: null,
      useValue: false,
      loading: false,
      alert: {
        show: false,
        message: "",
      },
      vehicleId: this.$route.params.vehicleId,
      stockCurrency: CurrencyEnum.BRL,
      isSubmit: false,
      maxDatePicker: "",
      currencyOptions: [
        {
          text: "R$",
          value: CurrencyEnum.BRL,
        },
        {
          text: "US$",
          value: CurrencyEnum.USD,
        },
      ],
    };
  },
  async created() {
    const formattedCurrentDate = format(new Date(), "dd/MM/yyyy");
    // data limite do date picker é a data atual
    this.maxDatePicker = format(new Date(), "yyyy-MM-dd");

    await this.getAllStocks();
    this.loading = this.vehicleRequestLoading;

    if (this.stockEdit) {
      const { stockTypeId, stockTypeName, currency, value, date, useValue } =
        this.stockEdit;

      this.stockSelected = stockTypeId;
      this.stockName = stockTypeName;

      // se tiver usando o modal para adicionar um novo valor para uma ação, só populamos o stockSelected e stockName
      if (!this.addNewValueStock) {
        this.stockCurrency = currency;
        this.stockValue = value;
        this.useValue = useValue;
      }

      // se estiver adicionando um novo valor a uma ação, vamos por a data current
      if (!this.addNewValueStock && date && date !== "0001-01-01T00:00:00") {
        this.stockTypeDate = format(parseStringDate(date), "dd/MM/yyyy", {
          locale: ptBR,
        });
      } else {
        this.stockTypeDate = formattedCurrentDate;
      }

      // só vira edit (para chamar o put) se não estivermos adicionando um valor para uma nova ação
      if (this.addNewValueStock) {
        this.edit = false;
      } else {
        this.edit = true;
      }
    } else {
      this.stockTypeDate = formattedCurrentDate;
    }
  },
  computed: {
    required(value) {
      return (value) => !!value || this.$t("required");
    },
  },
  watch: {},
  methods: {
    verifyMaxDate(date) {
      // data atual
      const currentDate = new Date();
      // pega a data atual em valor numérico (para comparar com a data digitada)
      const maxDate = currentDate.getTime();
      // espera a data vir no formato yyyy-MM-dd. Para transformar em um data e usar o method getTime.
      const selectedDate = new Date(`${date}T00:00:00`);

      // valida se a data selecionada é maior que a data atual, se for, volta false.
      if (selectedDate.getTime() > maxDate) return false;

      return true;
    },
    closeAlert() {
      this.alert.show = false;
      this.alert.message = "";
    },
    getModalTitle() {
      let title;

      if (this.addNewValueStock) {
        title = this.$t("add_new_value");
      } else if (this.vehicleId && this.edit) {
        title = this.$t("edit_stocks");
      } else {
        title = this.$t("stocks");
      }

      return title;
    },
    formatInputDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formatDateToSubmit(date) {
      const [day, month, year] = date.split("/");
      let formateDate;

      if (date.length) {
        formateDate = `${year}-${month}-${day}`;
      } else {
        formateDate = null;
      }

      return formateDate;
    },
    saveStockTypeDate(date) {
      this.stockTypeDate = this.formatInputDate(this.stockTypeCaptureDate);
      this.$refs.endMenuDateRef.save(this.stockTypeDate);
    },
    async getAllStocks() {
      await this.apiService
        .getRequest("stock")
        .then((result) => {
          // if para remover as ações já selecionadas
          if (
            this.vehicleInfo &&
            this.vehicleInfo.vehicleStockTypes.length > 0
          ) {
            const ExistingStocksId = this.vehicleInfo.vehicleStockTypes.map(
              (stock) => {
                return stock.stockTypeId;
              }
            );

            this.allStocks = JSON.parse(result.message).filter((stock) => {
              return !ExistingStocksId.includes(stock.Id);
            });
          } else {
            this.allStocks = JSON.parse(result.message);
          }
        })
        .catch((err) => {
          return err;
        });
    },
    checkForm() {
      this.loading = true;
      let result = this.$refs.form.validate();

      if (result) {
        this.loading = false;
        this.submit();
      } else {
        this.loading = false;
      }
    },
    async addStock(payload) {
      await this.apiService
        .postRequest(`investmentvehicle/vehiclestockvalue`, payload)
        .then((resp) => {
          this.$toast.success(this.$t("saved"));
          this.$emit("close", null);
          this.$emit("reloadStocks");
          this.$emit("reloadVehicleData");
        })
        .catch((err) => {
          if (err.body.statusCode === 400) {
            this.$toast.error(this.$t(err.body.message));
          } else {
            this.$toast.error(this.$t("an_error_occoured"));
          }
        });
    },
    async editStock(payload) {
      await this.apiService
        .putRequest(`investmentvehicle/vehiclestockvalue`, payload)
        .then((resp) => {
          this.$toast.success(this.$t("saved"));
          this.$emit("close", null);
          this.$emit("reloadStocks");
          this.$emit("reloadVehicleData");
        })
        .catch((err) => {
          if (err.body.statusCode === 400) {
            this.$toast.error(this.$t(err.body.message));
          } else {
            this.$toast.error(this.$t("an_error_occoured"));
          }
        });
    },
    submit: async function () {
      this.alert.show = false;
      this.loading = true;
      this.isSubmit = true;
      const valueToNumber = Number(this.stockValue);

      let payload = {
        investmentVehicleId: this.vehicleInfo.id,
        stockTypeId: this.stockSelected,
        value: valueToNumber,
        currency: this.stockCurrency,
        useValue: this.useValue,
        date: this.stockTypeDate
          ? this.formatDateToSubmit(this.stockTypeDate)
          : format(new Date(), "yyyy-MM-dd"),
      };

      if (this.edit) {
        payload.id = this.stockEdit.id;
      }

      // só chama o submit se a data passada, for menor ou igual a data atual.
      if (!this.verifyMaxDate(payload.date)) {
        this.alert.show = true;
        this.alert.message = this.$t("max_date");
        this.loading = false;
        return;
      }

      if (this.edit) {
        await this.editStock(payload);
      } else {
        await this.addStock(payload);
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
